













































































































import { Component, Prop } from "vue-property-decorator";
import FinishGroup from "@/components/designer/sidebar/frame/FinishGroup.vue";
import FinialGroup from "@/components/designer/sidebar/frame/FinialGroup.vue";
import { mixins } from "vue-class-component";
import { LayoutStore, ShapeStore } from "@/mixins/store";
import { Finish } from "@/models/products/finish";
import UIkit from "uikit";
import { ProductLineName } from "@/models/products/collection";

@Component({
  components: {
    FinishGroup,
    FinialGroup,
  },
})
export default class FrameSidebar extends mixins(ShapeStore, LayoutStore) {
  protected finishes: Finish[] | null = null;
  protected ProductLineName = ProductLineName;
  @Prop() isStockOnly!: boolean;
  @Prop({ default: true }) hasVent!: boolean;
  @Prop({ default: true }) hasValance!: boolean;
  async mounted() {
    this.finishes = this.umbrellaModel!.finishes;
  }

  protected toggleFinishModal(): void {
    UIkit.modal(document.getElementById("finish-modal") as HTMLElement).show();
  }

  protected toggleFinialModal(): void {
    UIkit.modal(document.getElementById("finial-modal") as HTMLElement).show();
  }
}
